import IconProps from 'types/icon';

const IdCard = ({ className }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 409.6 385"
      className={`svg-inject icon-svg ${className || 'icon-svg-md text-orange mb-3'}`}
    >
      <path
        className="lineal-stroke"
        d="M360.1 385H49.5C22.2 385 0 362.9 0 335.5V124.2c0-27.3 22.2-49.5 49.5-49.5h310.6c27.3 0 49.5 22.2 49.5 49.5v211.4c0 27.3-22.2 49.4-49.5 49.4zM49.5 97.6c-14.6 0-26.5 11.9-26.5 26.6v211.4c0 14.7 11.9 26.5 26.5 26.5h310.6c14.7 0 26.5-11.9 26.5-26.5V124.2c0-14.7-11.9-26.5-26.5-26.5l-310.6-.1z"
      />
      <path
        className="lineal-fill"
        d="M262.6 51.9h-16.5c-.5-22.8-19.4-40.9-42.2-40.4-22.1.5-39.9 18.3-40.4 40.4H147c-18.9 0-34.2 15.3-34.2 34.2s15.3 34.2 34.2 34.2h115.6c18.9 0 34.2-15.3 34.2-34.2s-15.3-34.2-34.2-34.2z"
      />
      <path
        className="lineal-stroke"
        d="M262.6 131.9H147c-25.2-.4-45.4-21.1-45-46.4.4-24.7 20.3-44.7 45-45h6.5c6.8-28.3 35.3-45.8 63.6-39 19.3 4.6 34.4 19.7 39 39h6.5c25.2.4 45.4 21.1 45 46.4-.4 24.6-20.3 44.6-45 45zM147 63.4c-12.6.3-22.5 10.7-22.3 23.2.3 12.2 10.1 22 22.3 22.3h115.6c12.6-.3 22.5-10.7 22.3-23.2-.3-12.2-10.1-22-22.3-22.3h-16.5c-6.2 0-11.3-5-11.5-11.2-.4-16.5-14.1-29.5-30.6-29.1-15.9.4-28.7 13.2-29.1 29.1-.1 6.2-5.2 11.2-11.5 11.2H147zm-4 234.3c-34.2-.1-61.9-28-61.7-62.3s28-61.9 62.3-61.7c5.3 0 10.5.7 15.6 2.1 6.1 1.6 9.8 7.9 8.1 14.1s-7.9 9.8-14.1 8.1c-20.8-5.6-42.3 6.7-47.9 27.6s6.7 42.3 27.6 47.9 42.3-6.7 47.9-27.6c.9-3.3 1.3-6.7 1.3-10.2.2-6.3 5.5-11.3 11.8-11.1 6.1.2 11 5.1 11.1 11.1 0 34.2-27.7 61.9-62 62z"
      />
      <path
        className="lineal-fill"
        d="M180.5 300.2c-11.3-7-24.3-10.6-37.5-10.6-39.6.1-71.7 32.2-71.8 71.9v10.7h143.7v-10.7c-.1-25.1-13.1-48.2-34.4-61.3"
      />
      <path
        className="lineal-stroke"
        d="M214.9 383.6H71.2c-6.3 0-11.5-5.1-11.5-11.4v-10.7c.5-46 38.2-82.9 84.2-82.4 45.3.5 81.9 37.1 82.4 82.4v10.7c0 6.3-5.1 11.4-11.4 11.4zM82.6 360.7h120.8c-.4-33.3-27.8-60-61.2-59.6-32.7.4-59.1 26.8-59.6 59.6zm255.9-111.9h-76.9c-6.3-.2-11.3-5.5-11.1-11.8.2-6.1 5.1-11 11.1-11.1h76.9c6.3-.2 11.6 4.8 11.8 11.1.2 6.3-4.8 11.6-11.1 11.8h-.7zm0 82.6h-76.9c-6.4 0-11.5-5.1-11.5-11.5s5.1-11.5 11.5-11.5h76.9c6.4 0 11.5 5.1 11.5 11.5s-5.2 11.5-11.5 11.5z"
      />
    </svg>
  );
};

export default IdCard;
