import Tiles1 from './Tiles1';
import Tiles2 from './Tiles2';
import Tiles3 from './Tiles3';
import Tiles4 from './Tiles4';
import Tiles5 from './Tiles5';
import Tiles6 from './Tiles6';
import Tiles7 from './Tiles7';
import Tiles8 from './Tiles8';
import Tiles9 from './Tiles9';
import Tiles10 from './Tiles10';
import Tiles11 from './Tiles11';

export { Tiles1, Tiles2, Tiles3, Tiles4, Tiles5, Tiles6, Tiles7, Tiles8, Tiles9, Tiles10, Tiles11 };
