import IconProps from 'types/icon';

const Insurance = ({ className }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 441.41 512"
      className={`svg-inject icon-svg ${className || 'icon-svg-sm text-pink me-5 mt-1'}`}
    >
      <path
        className="lineal-fill"
        d="M254.93,457a46.24,46.24,0,0,1-24.13-40.62V285.62A70,70,0,0,0,307.94,270a100.73,100.73,0,0,0-81.62-41.61H115.72A100.88,100.88,0,0,0,14.84,329.31V497.16H327.2v-.81Z"
      />
      <path
        className="lineal-stroke"
        d="M426.57,270.78a14.85,14.85,0,0,0-14.85,14.84V416.34a31.41,31.41,0,0,1-16.38,27.59l-66.66,36.32L262,443.93a31.4,31.4,0,0,1-16.39-27.59V305.05a85.35,85.35,0,0,0,12.31.89c1.26,0,2.51,0,3.76-.1l1,0c1.13-.06,2.25-.14,3.37-.25l1.59-.18,1.65-.2a83.72,83.72,0,0,0,41.13-17.47l1.09-.86,1.28-1.07c.85-.72,1.69-1.47,2.52-2.23l.41-.38c.95-.88,1.88-1.79,2.78-2.71l0,0h0a85,85,0,0,0,10.13-12.56,85.74,85.74,0,0,0,28,26.55,14.84,14.84,0,0,0,15-25.63A55.78,55.78,0,0,1,349,243.37a14.85,14.85,0,0,0-13.58-8.85H321.94a14.85,14.85,0,0,0-13.58,8.85c-.45,1-.94,2-1.44,3a116.46,116.46,0,0,0-45.39-27.21,87.22,87.22,0,0,0,27.23-63.34V87.5a14.85,14.85,0,0,0-29.69,0v68.29A57.86,57.86,0,0,1,202,213.58H140A57.86,57.86,0,0,1,83,155.79V87.5a57.87,57.87,0,0,1,57.81-57.81h60.48a14.85,14.85,0,0,0,0-29.69H140.78a87.6,87.6,0,0,0-87.5,87.5v68.29a87.23,87.23,0,0,0,27.19,63.3A115.93,115.93,0,0,0,0,329.31V497.16A14.85,14.85,0,0,0,14.84,512H327.2c.27,0,.55,0,.82,0s.44,0,.66,0a14.76,14.76,0,0,0,7.1-1.81L409.54,470a61.06,61.06,0,0,0,31.87-53.65V285.63A14.85,14.85,0,0,0,426.57,270.78Z"
      />
      <path
        className="lineal-fill"
        d="M29.69,482.31v-153a86.13,86.13,0,0,1,86-86H140l.74,0h15.4V387.46a14.84,14.84,0,1,0,29.68,0V243.29h15.4l.74,0h24.32a86.35,86.35,0,0,1,60.4,24.82,55,55,0,0,1-20.58,7.54l-.15,0c-1.15.17-2.31.29-3.47.38l-.56.06c-1.24.09-2.49.13-3.74.14H258a54.76,54.76,0,0,1-21.38-4.3A14.84,14.84,0,0,0,216,285.62V416.34A61.12,61.12,0,0,0,247.82,470l22.6,12.31Z"
      />
      <path
        className="lineal-stroke"
        d="M327.19,405.92a14.81,14.81,0,0,1-7.22-1.87l-24.85-13.86a14.84,14.84,0,0,1,14.45-25.93L325.24,373l34.06-30.14A14.84,14.84,0,1,1,379,365.09L337,402.2A14.82,14.82,0,0,1,327.19,405.92Z"
      />
    </svg>
  );
};

export default Insurance;
