enum LinkType {
  red = 'red',
  blue = 'blue',
  green = 'green',
  yellow = 'yellow',
  violet = 'violet',
  orange = 'orange',
  fuchsia = 'fuchsia'
}

export { LinkType };
