import Process1 from './Process1';
import Process2 from './Process2';
import Process3 from './Process3';
import Process4 from './Process4';
import Process5 from './Process5';
import Process6 from './Process6';
import Process7 from './Process7';
import Process8 from './Process8';
import Process9 from './Process9';
import Process10 from './Process10';
import Process11 from './Process11';
import Process12 from './Process12';
import Process13 from './Process13';
import Process14 from './Process14';
import Process15 from './Process15';
import Process16 from './Process16';
import Process17 from './Process17';

export {
  Process1,
  Process2,
  Process3,
  Process4,
  Process5,
  Process6,
  Process7,
  Process8,
  Process9,
  Process10,
  Process11,
  Process12,
  Process13,
  Process14,
  Process15,
  Process16,
  Process17
};
