const Hex = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 549.53" className="svg-inject icon-svg w-100 h-100">
      <path
        className="svg-fill"
        d="M250 549.39c-15.51 0-30.05-3.46-40.94-9.75l-168-97C18.5 429.63.14 397.82.14 371.75v-194c0-26.07 18.36-57.88 40.94-70.91l168-97C220 3.6 234.49.14 250 .14s30.05 3.46 40.94 9.75l168 97c22.58 13 40.94 44.84 40.94 70.91v194c0 26.07-18.36 57.88-40.94 70.91l-168 97c-10.89 6.22-25.43 9.68-40.94 9.68z"
      />
    </svg>
  );
};

export default Hex;
