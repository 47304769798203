import IconProps from 'types/icon';

const GlobeTwo = ({ className }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256.01 256"
      className={`svg-inject icon-svg solid-mono ${className || 'icon-svg-md text-grape mb-5'}`}
    >
      <g>
        <g>
          <path
            className="fill-secondary"
            d="M128.11,256V256h-.24a126.37,126.37,0,0,1-22-1.84,8,8,0,1,1,2.72-15.76A114.68,114.68,0,0,0,128,240a8.06,8.06,0,0,1,8.07,8,8,8,0,0,1-7.94,8Zm33.52-12.5a8,8,0,0,1,4.77-10.25A112.18,112.18,0,0,0,184.06,225a8,8,0,0,1,8,13.85,128.36,128.36,0,0,1-20.19,9.46,8,8,0,0,1-10.26-4.79Zm-97.5-4.56a128.83,128.83,0,0,1-18.27-12.78,8,8,0,1,1,10.25-12.27,114.33,114.33,0,0,0,16,11.2,8,8,0,1,1-8,13.85Zm150.69-27.71a8,8,0,0,1-1-11.26A112.91,112.91,0,0,0,225,184a8,8,0,0,1,13.86,8,130.3,130.3,0,0,1-12.78,18.26,8,8,0,0,1-11.28,1Zm-197.59-19A128.41,128.41,0,0,1,7.76,172a8,8,0,1,1,15-5.49,112.8,112.8,0,0,0,8.29,17.67,8,8,0,1,1-13.84,8ZM244.8,156.7a8,8,0,0,1-6.5-9.26A112.3,112.3,0,0,0,240,128a8.23,8.23,0,0,1,8-8.26,7.81,7.81,0,0,1,8,8.76,124.89,124.89,0,0,1-1.92,21.72,8,8,0,0,1-9.26,6.48ZM8,136.13a7.89,7.89,0,0,1-8-7.87s.61-15,1.86-22.18a8,8,0,1,1,15.76,2.7A114.47,114.47,0,0,0,16,128,8.09,8.09,0,0,1,8,136.13ZM233.1,89.25a110.41,110.41,0,0,0-8.32-17.63,8,8,0,0,1,13.83-8.08,129,129,0,0,1,9.52,20.17,8,8,0,0,1-15,5.54ZM19.9,75.18A8,8,0,0,1,17,64.26,126.41,126.41,0,0,1,29.73,46,8,8,0,1,1,42,56.21a112.72,112.72,0,0,0-11.17,16,8,8,0,0,1-10.93,3ZM199.66,41.94a113.17,113.17,0,0,0-16-11.16,8,8,0,1,1,7.95-13.87,127.39,127.39,0,0,1,18.3,12.75,8,8,0,0,1-10.24,12.28ZM60.78,28.26a8,8,0,0,1,2.88-11A128,128,0,0,1,83.84,7.82a8,8,0,1,1,5.52,15,112.17,112.17,0,0,0-17.63,8.31,8,8,0,0,1-11-2.88Zm86.29-10.64A112.4,112.4,0,0,0,128,16a8.17,8.17,0,0,1-8.19-8,7.84,7.84,0,0,1,7.81-8H128a127.72,127.72,0,0,1,21.8,1.86,8,8,0,0,1-2.71,15.76Z"
          />
          <path
            className="fill-primary"
            d="M128,32a96,96,0,1,0,96,96A96.11,96.11,0,0,0,128,32Zm62.61,145.66a103,103,0,0,0-14.49-7.76,160.22,160.22,0,0,0,5-33.9h26.48A79.47,79.47,0,0,1,190.59,177.66ZM48.4,136H74.88a161.6,161.6,0,0,0,5,33.9,104.11,104.11,0,0,0-14.5,7.76A79.47,79.47,0,0,1,48.4,136Zm17-57.66a103.14,103.14,0,0,0,14.5,7.76,160.2,160.2,0,0,0-5,33.9H48.4a79.47,79.47,0,0,1,17-41.66ZM120,79.7a106.49,106.49,0,0,1-20-3.43c5.41-13,12.6-22.11,20-26Zm0,16V120H90.86A145.12,145.12,0,0,1,95,91.49a122.72,122.72,0,0,0,25,4.21Zm0,40.3v24.3a121.26,121.26,0,0,0-25,4.23A144.37,144.37,0,0,1,90.86,136H120Zm0,40.3v29.48c-7.4-3.94-14.59-13-20-26a104.12,104.12,0,0,1,20-3.44Zm16,0a106.21,106.21,0,0,1,20,3.43c-5.4,13-12.59,22.11-20,26Zm0-16V136H165.1a144.37,144.37,0,0,1-4.16,28.51,122.49,122.49,0,0,0-25-4.21Zm0-40.3V95.7a121.14,121.14,0,0,0,25-4.23A142.91,142.91,0,0,1,165.1,120H136Zm0-40.3V50.24c7.41,3.94,14.6,13,20,26A104.36,104.36,0,0,1,136,79.7Zm27.94-23.08a80.19,80.19,0,0,1,15.25,10A88.15,88.15,0,0,1,171,70.83a98.1,98.1,0,0,0-7.12-14.21Zm-79,14.21a86.72,86.72,0,0,1-8.12-4.25,80.12,80.12,0,0,1,15.24-10A95.14,95.14,0,0,0,84.94,70.83Zm0,114.34a98.11,98.11,0,0,0,7.12,14.21,80.12,80.12,0,0,1-15.24-10A86.72,86.72,0,0,1,84.94,185.17Zm86.1,0a88.15,88.15,0,0,1,8.13,4.25,80.19,80.19,0,0,1-15.25,10A99.14,99.14,0,0,0,171,185.17ZM181.1,120a161,161,0,0,0-5-33.9,104.57,104.57,0,0,0,14.49-7.76,79.47,79.47,0,0,1,17,41.66Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default GlobeTwo;
