// -------- icons -------- //
import Bulb from 'icons/solid-mono/Bulb';
import Shield from 'icons/lineal/Shield';
import Rocket from 'icons/lineal/Rocket';
import Design from 'icons/lineal/Design';
import Loading from 'icons/lineal/Loading';
import Compare from 'icons/solid-mono/Compare';
import Savings from 'icons/lineal/Savings';
import LightBulb from 'icons/lineal/LightBulb';
import DeliveryBox from 'icons/solid-mono/DeliveryBox';
import PieChartTwo from 'icons/lineal/PieChartTwo';
import SmartphoneTwo from 'icons/lineal/SmartphoneTwo';
import CloudComputing from 'icons/lineal/CloudComputing';
  
export const processList1 = [
  {
    no: '1',
    className: 'me-lg-6',
    title: 'Predictive Analytics',
    subtitle: 'We make the most of your data with our predictive analytics tools. If you base your decisions on hard data, you can improve your SEO and see a rise in site visitors and revenue.'
  },
  {
    no: '2',
    title: 'Keyword Research',
    className: 'ms-lg-15 mt-6',
    subtitle: 'Discover the best keywords for your industry with AI based keyword research. Improve your position in search engines, attract more targeted visitors and increase your ROI with less work.'
  },
  {
    no: '3',
    title: 'Voice Search',
    className: 'mx-lg-6 ms-lg-10 mt-6',
    subtitle: 'Optimise for voice searches to raise your company\'s visibility. Site traffic can be increased by optimisation for long-tail keywords in written content.'
  },
  {
    no: '4',
    title: 'AI SEO Content',
    className: 'mx-lg-6 ms-lg-0 mt-6',
    subtitle: 'Fast creation of high-quality, search engine optimised articles. With creative paraphrasing and human editing, your content will be optimised for higher rankings and more traffic.'
  }
  
];
export const seoAuditList1 = [
  {
    no: '1',
    className: 'me-lg-6',
    title: 'Why do you need an SEO Audit?',
    subtitle: 'The success of a website relies heavily on search engine optimisation (SEO). It helps to attract traffic to your site, improves your rankings in search engine results pages (SERPs), and generally raises your profile online. If you want your website to perform as well as it possibly can in search engine results, an SEO audit service can point you where tweaks are needed.'
  },
  {
    no: '2',
    title: 'What is in an SEO Audit?',
    className: 'ms-lg-15 mt-6',
    subtitle: 'A website\'s overall search engine optimisation effectiveness can be evaluated with the use of an SEO audit. It examines everything from the site\'s keywords and content to its links and technical SEO. Title tags, meta descriptions, optimised content, optimised images, internal linking structure, and other on-page factors are just some of the things that will be looked at throughout the audit. Off-page factors like link development, social media presence, and other external aspects will also be assessed.'
  },
  {
    no: '3',
    title: 'SEO recommendations',
    className: 'mx-lg-6 ms-lg-10 mt-6',
    subtitle: 'An SEO audit is a comprehensive look at where your site is in the search engine results pages and where changes can be made. An audit will also provide actionable recommendations for optimisation and best practises to apply to boost the overall performance of the website.'
  },
  {
    no: '4',
    title: 'Free Online SEO Audit',
    className: 'mx-lg-6 ms-lg-0 mt-6',
    subtitle: 'You can save both money and effort by using our free audit service to evaluate your website\'s effectiveness.'
  }
  
];

// 
//An SEO audit is an essential step in any successful SEO campaign. 
 

// used in the process-6 block
export const processList2 = [
  {
    no: '01',
    title: 'Concept',
    subtitle: 'Nulla vitae elit libero elit non porta gravida eget metus cras. Aenean eu leo quam. Pellentesque ornare.'
  },
  {
    no: '02',
    title: 'Prepare',
    subtitle: 'Vestibulum id ligula porta felis euismod semper. Sed posuere consectetur est at lobortis.'
  },
  {
    no: '03',
    title: 'Retouch',
    subtitle: 'Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Nulla vitae elit libero.'
  },
  {
    no: '04',
    title: 'Finalize',
    subtitle: 'Integer posuere erat, consectetur adipiscing elit. Fusce dapibus, tellus ac cursus commodo.'
  }
];

// used in the process-2 block
export const processList3 = [
  {
    no: '1',
    className: 'me-lg-6',
    title: 'Collect Ideas',
    subtitle: 'Nulla vitae elit libero pharetra augue dapibus. Praesent commodo cursus.'
  },
  {
    no: '2',
    title: 'Data Analysis',
    className: 'ms-lg-13 mt-6',
    subtitle: 'Vivamus sagittis lacus vel augue laoreet. Etiam porta sem malesuada magna.'
  },
  {
    no: '3',
    title: 'Finalize Product',
    className: 'mx-lg-6 mt-6',
    subtitle: 'Cras mattis consectetur purus sit amet. Aenean lacinia bibendum nulla sed.'
  }
];

// used in the process-3 block
export const processList4 = [
  {
    no: '1',
    title: 'Collect Ideas',
    subtitle: 'Nulla vitae elit libero pharetra augue dapibus. Praesent commodo cursus.'
  },
  {
    no: '2',
    title: 'Data Analysis',
    subtitle: 'Vivamus sagittis lacus vel augue laoreet. Etiam porta sem malesuada magna.'
  },
  {
    no: '3',
    title: 'Finalize Product',
    subtitle: 'Cras mattis consectetur purus sit amet. Aenean lacinia bibendum nulla sed.'
  }
];

// used in the process-4 block
export const processList5 = [
  {
    Icon: Shield,
    color: 'red',
    title: '1. Secured Transactions',
    subtitle: 'Etiam porta malesuada magna mollis euismod sem.'
  },
  {
    Icon: Savings,
    color: 'green',
    title: '2. Budget Planning',
    subtitle: 'Etiam porta malesuada magna mollis euismod sem.'
  },
  {
    Icon: Loading,
    color: 'yellow',
    title: '3. Up to Date',
    subtitle: 'Etiam porta malesuada magna mollis euismod sem.'
  }
];

// used in the process-5 block
export const processList6 = [
  {
    id: 1,
    color: 'green',
    Icon: CloudComputing,
    title: '1. Download',
    description: 'Nulla vitae elit libero elit non porta eget.'
  },
  {
    id: 2,
    color: 'red',
    Icon: SmartphoneTwo,
    title: '2. Set Profile',
    description: 'Nulla vitae elit libero elit non porta eget.'
  },
  {
    id: 3,
    color: 'aqua',
    Icon: Rocket,
    title: '3. Start',
    description: 'Nulla vitae elit libero elit non porta eget.'
  }
];

// used in the process-8 block
export const processList7 = [
  {
    id: 1,
    Icon: LightBulb,
    title: 'Collect Ideas',
    description: 'Nulla vitae elit libero pharetra augue dapibus.'
  },
  {
    id: 2,
    Icon: PieChartTwo,
    title: 'Data Analysis',
    description: 'Vivamus sagittis lacus augue laoreet vel.'
  },
  {
    id: 3,
    Icon: Design,
    title: 'Magic Touch',
    description: 'Cras mattis consectetur purus sit amet.'
  }
];

// used in the process-9 block
export const processList8 = [
  {
    id: 1,
    Icon: Shield,
    iconColor: 'yellow',
    title: 'Secured Transactions',
    className: 'd-flex flex-row mb-5',
    description: 'Nulla vitae elit libero pharetra augue dapibus. Praesent commodo cursus.'
  },
  {
    id: 2,
    Icon: Savings,
    iconColor: 'green',
    title: 'Bills Planning',
    className: 'd-flex flex-row mb-5',
    description: 'Vivamus sagittis lacus vel augue laoreet. Etiam porta sem malesuada magna.'
  },
  {
    id: 3,
    Icon: Loading,
    iconColor: 'red',
    title: 'Always up to date',
    className: 'd-flex flex-row',
    description: 'Cras mattis consectetur purus sit amet. Aenean lacinia bibendum nulla sed.'
  }
];

// used in the process-12 block
export const processList9 = [
  {
    no: '01',
    className: '',
    color: 'purple',
    title: 'Collect Ideas',
    subtitle: 'Nulla vitae elit libero pharetra augue dapibus. Fusce dapibus, tellus ac cursus commodo.'
  },
  {
    no: '02',
    color: 'green',
    title: 'Data Analysis',
    className: 'mt-8 ms-lg-10',
    subtitle: 'Vivamus sagittis lacus vel augue laoreet tortor mauris condimentum fermentum.'
  },
  {
    no: '03',
    color: 'orange',
    className: 'mt-8',
    title: 'Finalize Product',
    subtitle: 'Cras mattis consectetur purus sit amet massa justo sit amet risus consectetur magna elit.'
  }
];

// used in the process-13 block
export const processList10 = [
  {
    id: 1,
    Icon: Bulb,
    title: '1. Collect Ideas',
    description: 'Etiam porta malesuada magna mollis euismod consectetur leo elit.'
  },
  {
    id: 2,
    Icon: Compare,
    title: '2. Data Analysis',
    description: 'Etiam porta malesuada magna mollis euismod consectetur leo elit.'
  },
  {
    id: 1,
    Icon: DeliveryBox,
    title: '3. Finalize Product',
    description: 'Etiam porta malesuada magna mollis euismod consectetur leo elit.'
  }
];

// used in the process-15 block
export const processList11 = [
  { id: 1, title: 'Creative Ideas', description: 'Nulla vitae elit libero a augue donec id elit non mi porta.' },
  { id: 2, title: 'Magic Touch', description: 'Nulla vitae elit libero a augue donec id elit non mi porta.' },
  { id: 3, title: 'Data Analysis', description: 'Nulla vitae elit libero a augue donec id elit non mi porta.' },
  { id: 4, title: 'Finalize Product', description: 'Nulla vitae elit libero a augue donec id elit non mi porta.' }
];

// used in the about-25 block
export const processList12 = [
  {
    no: '1',
    title: 'Concept',
    description: 'Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida.'
  },
  {
    no: '2',
    title: 'Prepare',
    description: 'Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida.'
  },
  {
    no: '3',
    title: 'Retouch',
    description: 'Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida.'
  },
  {
    no: '4',
    title: 'Finalize',
    description: 'Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida.'
  }
];
