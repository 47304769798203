import IconProps from 'types/icon';

const ShopTwo = ({ className }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 466.4"
      className={`svg-inject icon-svg ${className || 'icon-svg-sm text-green me-4'}`}
    >
      <path
        className="lineal-fill"
        d="M201.4 172.5h-96l26.1-158.4h83zm205.2 0h-96L297.5 14.1h83zm-216.7 97.1c7.2-7 16.8-10.9 26.9-10.8 9.6 0 18.8 3.1 26.9 5.8 4 1.4 8.1 2.6 12.3 3.5 4.2-.9 8.3-2.1 12.3-3.5 8.1-2.7 17.3-5.8 26.9-5.8 10-.1 19.7 3.7 26.9 10.8 24.4 22.9 15.9 48.4 10.9 63.7l-.3.8c-4.6 14-22 59.8-51.4 59.8-15.6 0-20.9-2.5-24.8-4.4l-.6-.3-.6.3c-3.9 1.9-9.1 4.4-24.8 4.4-29.3 0-46.7-45.8-51.4-59.8l-.3-.8c-4.8-15.3-13.3-40.8 11.1-63.7z"
      />
      <path
        className="lineal-stroke"
        d="M511.1 147.4l-6.4-25.9c-1.9-7.6-9.6-12.1-17.2-10.2-7.5 1.9-12.1 9.5-10.3 17l6.4 25.9c.4 1.8-.7 3.6-2.5 4.1-.3.1-.5.1-.8.1h-61.7l-14.2-86-7.3-44.1h52.6c1.5 0 2.9 1 3.2 2.5l6.6 26.8c1.9 7.6 9.6 12.1 17.2 10.2 7.5-1.9 12.1-9.5 10.3-17L480.5 24C477 9.9 464.3 0 449.8 0H62.2C47.7 0 35 9.9 31.5 24L.9 147.4c-4.2 17 6.1 34.1 23.1 38.3 2.5.6 5.1.9 7.6.9h8.1v265.6c0 7.8 6.3 14.1 14.2 14.1h404.2c7.8 0 14.1-6.3 14.1-14.1V257.8c0-7.8-6.3-14.2-14.1-14.2s-14.2 6.3-14.2 14.1V438H68V186.7h412.4c17.5 0 31.6-14.2 31.6-31.6 0-2.6-.3-5.2-.9-7.7zM227.5 28.3h56.9l10.8 130.1h-78.4l10.7-130.1zM29 157.1c-.7-.8-.9-1.9-.6-2.9L59 30.8c.4-1.5 1.7-2.5 3.2-2.5h52.6l-5.3 32.1-16.2 98H31.6c-1 0-2-.5-2.6-1.3zm98.3-30.8l16.2-98h55.6l-10.7 130.1H122l5.3-32.1zm196.3 32.1L315.2 57l-2.4-28.7h55.6l16.2 98 5.3 32.1h-66.3z"
      />
      <path
        className="lineal-stroke"
        d="M256 220c-7.8 0-14.1 6.3-14.1 14.1v15c-7.4-2.3-15.9-4.4-25-4.4-13.6-.1-26.8 5.1-36.6 14.6-31 29.1-19.9 62.4-14.6 78.4l.3.8c6.9 20.9 27 69.5 64.8 69.5 12.6 0 19.9-1.5 25.3-3.4 5.4 2 12.8 3.4 25.3 3.4 37.8 0 57.9-48.6 64.8-69.5l.3-.8c5.3-16 16.3-49.3-14.6-78.4-9.8-9.5-22.9-14.7-36.6-14.6-9.1 0-17.6 2.1-25 4.4v-15c-.1-7.8-6.4-14.1-14.3-14.1zm39.2 53c6.4-.1 12.7 2.4 17.2 6.9 16.5 15.5 13 31.3 7.1 48.9l-.3.8c-8.2 24.9-23.2 50.1-37.9 50.1-12.4 0-15.8-1.7-18.6-3l-.7-.3c-3.9-1.8-8.3-1.8-12.2 0l-.6.3c-2.8 1.3-6.2 3-18.6 3-14.8 0-29.7-25.1-37.9-50l-.3-.8c-5.8-17.6-9.3-33.4 7.1-48.9 4.5-4.6 10.8-7.1 17.2-6.9 7.3 0 15 2.6 22.5 5 4.6 1.7 9.4 3 14.2 4 1.7.3 3.3.3 5 0 4.8-1 9.6-2.3 14.2-4 7.6-2.6 15.3-5.1 22.6-5.1z"
      />
    </svg>
  );
};

export default ShopTwo;
