import IconProps from 'types/icon';

const Gift = ({ className }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 409.6 388.9"
      className={`svg-inject icon-svg ${className || 'icon-svg-md text-blue mb-3'}`}
    >
      <path
        className="lineal-fill"
        d="M251.1 258.8l-44.3-22.9c-1.3-.6-2.7-.6-4 0l-44.4 22.9c-2.1 1.1-4.7.3-5.8-1.9-.3-.6-.5-1.3-.5-2v-113l105.4.2v112.8c0 2.4-1.9 4.3-4.3 4.3-.8 0-1.5-.1-2.1-.4z"
      />
      <path
        className="lineal-stroke"
        d="M253.1 270.7c-2.5 0-5-.6-7.2-1.8l-41.1-21.2-41.1 21.2c-2.2 1.2-4.7 1.8-7.2 1.8-8.7 0-15.8-7.1-15.8-15.8v-113c0-6.3 5.1-11.5 11.5-11.5l105.3.2c6.3 0 11.4 5.1 11.4 11.5v112.8c0 8.7-7.1 15.8-15.8 15.8zm-48.3-46.8c2.5 0 5 .6 7.2 1.8l34 17.6v-89.6l-82.4-.2v89.8l34-17.6c2.3-1.2 4.7-1.8 7.2-1.8z"
      />
      <path
        className="lineal-stroke"
        d="M319.5 388.9H90.1c-29.6 0-53.6-24-53.6-53.6V142.1c0-6.3 5.1-11.5 11.5-11.5h313.8c6.3 0 11.5 5.1 11.5 11.5v25.5c.2 6.3-4.8 11.6-11.1 11.8-6.3.2-11.6-4.8-11.8-11.1v-14.7H59.3v181.7c0 17 13.8 30.7 30.7 30.7h229.5c17 0 30.7-13.8 30.7-30.7V228.8c0-6.3 5.1-11.5 11.5-11.5 6.3 0 11.5 5.1 11.5 11.5v106.4c-.1 29.7-24.1 53.6-53.7 53.7z"
      />
      <path
        className="lineal-fill"
        d="M356.5 142.1H53.2c-23 0-41.7-18.7-41.7-41.7 0-23 18.7-41.7 41.7-41.7h303.3c23 0 41.7 18.7 41.7 41.7-.1 23-18.7 41.7-41.7 41.7z"
      />
      <path
        className="lineal-stroke"
        d="M356.5 153.5H53.2C23.8 153.5 0 129.7 0 100.4s23.8-53.2 53.2-53.2h303.3c29.4 0 53.1 23.8 53.1 53.2s-23.8 53.1-53.1 53.1zM53.2 70.1c-16.7-.3-30.5 13-30.8 29.7s13 30.5 29.7 30.8h304.4c16.7.3 30.5-13 30.8-29.7.3-16.7-13-30.5-29.7-30.8H53.2z"
      />
      <path className="lineal-fill" d="M203.2 58.7c-5.8-31.3-35.8-52-67.1-46.3-23.5 4.3-42 22.7-46.3 46.3h113.4z" />
      <path
        className="lineal-stroke"
        d="M203.2 70.1H89.8c-6.3 0-11.5-5.1-11.4-11.5 0-.7.1-1.3.2-2C85.4 19 121.4-5.9 158.9 1c28.3 5.2 50.4 27.3 55.6 55.6 1.1 6.2-3 12.2-9.2 13.3-.7.1-1.4.2-2.1.2zm-97.4-22.9h81.4c-12.1-22.5-40.2-30.9-62.6-18.8-8 4.3-14.5 10.9-18.8 18.8z"
      />
      <path className="lineal-fill" d="M319.8 58.7c-5.8-31.3-35.8-52-67.1-46.3-23.5 4.3-42 22.7-46.3 46.3h113.4z" />
      <path
        className="lineal-stroke"
        d="M319.8 70.1H206.4c-6.3 0-11.4-5.1-11.4-11.4 0-.7.1-1.4.2-2C201.9 19 237.9-5.9 275.5 1c28.3 5.2 50.4 27.3 55.6 55.6 1.1 6.2-3 12.2-9.3 13.3-.6.1-1.3.2-2 .2zm-97.4-22.9h81.4c-12.1-22.5-40.2-30.9-62.6-18.8-8 4.3-14.5 10.9-18.8 18.8z"
      />
    </svg>
  );
};

export default Gift;
