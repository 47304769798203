import IconProps from 'types/icon';

const Padlock = ({ className }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 324.02 512"
      className={`svg-inject icon-svg ${className || 'icon-svg-sm text-pink me-5 mt-1'}`}
    >
      <path
        className="lineal-fill"
        d="M14.31,241.68V439.17a58.52,58.52,0,0,0,58.52,58.52H251.19a58.52,58.52,0,0,0,58.52-58.52V241.68ZM162,407.85a47.7,47.7,0,1,1,47.7-47.7A47.7,47.7,0,0,1,162,407.85Z"
      />
      <path
        className="lineal-stroke"
        d="M251.19,512H72.83A72.91,72.91,0,0,1,0,439.17V241.68a14.31,14.31,0,0,1,14.31-14.3h295.4A14.31,14.31,0,0,1,324,241.68V439.17A72.91,72.91,0,0,1,251.19,512ZM28.62,256V439.17a44.26,44.26,0,0,0,44.21,44.21H251.19a44.26,44.26,0,0,0,44.21-44.21V256ZM162,422.16a62,62,0,1,1,62-62A62.08,62.08,0,0,1,162,422.16Zm0-95.4a33.39,33.39,0,1,0,33.39,33.39A33.44,33.44,0,0,0,162,326.76Z"
      />
      <path
        className="lineal-stroke"
        d="M68.56,234.54H20.38a14.31,14.31,0,0,1,0-28.62H68.56a14.31,14.31,0,0,1,0,28.62Z"
      />
      <path
        className="lineal-stroke"
        d="M303.64,234.54H255.46a14.31,14.31,0,0,1,0-28.62h48.18a14.31,14.31,0,0,1,0,28.62Z"
      />
      <path
        className="lineal-stroke"
        d="M45.14,256a14.3,14.3,0,0,1-14.31-14.31V124a124.16,124.16,0,0,1,124-124h14.32a124.17,124.17,0,0,1,124,124V225.16a14.31,14.31,0,1,1-28.62,0V124a95.51,95.51,0,0,0-95.4-95.4H154.85A95.51,95.51,0,0,0,59.44,124V241.68A14.3,14.3,0,0,1,45.14,256Z"
      />
    </svg>
  );
};

export default Padlock;
