import IconProps from 'types/icon';

const Earth = ({ className }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 409.6 409.6"
      className={`svg-inject icon-svg ${className || 'icon-svg-sm text-primary me-4'}`}
    >
      <path
        className="lineal-stroke"
        d="M204.8 409.6C91.9 409.6 0 317.7 0 204.8c0-28.1 5.7-55.9 17-81.7C62.1 19.4 182.8-28.1 286.6 17c45.5 19.8 82.3 55.5 103.5 100.4 12.9 27.3 19.6 57.2 19.5 87.4v4.1c-.4 23.5-4.9 46.7-13.3 68.7-2.1 5.5-4.5 11.2-7.2 16.6 0 0 0 .1-.1.1l-.1.3c-7.8 16-17.7 30.8-29.3 44.2-4.1 4.9-11.4 5.5-16.2 1.4-4.9-4.1-5.5-11.4-1.4-16.2.1-.1.1-.2.2-.2 10.4-11.9 19.1-25.2 26-39.4l.2-.3c2.4-4.9 4.5-9.8 6.4-14.7 7.4-19.5 11.4-40.1 11.8-60.9v-3.8c.1-26.8-5.9-53.3-17.3-77.6C335 54.8 257.3 13.3 178.1 25 116.6 34 62.9 75.2 38 132.3c-10 22.9-15.1 47.6-15 72.5 0 100.2 81.5 181.8 181.8 181.8 26.1.1 52-5.6 75.7-16.5 5.8-2.6 12.6.1 15.2 5.9 2.5 5.7 0 12.4-5.6 15-26.7 12.3-55.8 18.7-85.3 18.6z"
      />
      <path
        className="lineal-fill"
        d="M398.1 204.8v3.9c-.4 22.2-4.6 44.1-12.5 64.8-2 5.4-4.3 10.6-6.8 15.7l-.1.3c-19.7-8.2-34.3-25.4-39.3-46.1l-12.2-50.7c-.7-2.8-2.5-5.3-4.9-6.8L311 179c-3.5-2.2-5.5-6.1-5.2-10.2l.8-9.8c.3-3.4 1.9-6.5 4.5-8.7l27.4-23.1c2.6-2.2 5.9-3.3 9.3-3l31.9-2c12.1 25.8 18.4 54 18.4 82.6z"
      />
      <path
        className="lineal-stroke"
        d="M378.6 301c-1.5 0-3-.3-4.4-.9-23.1-9.6-40.2-29.7-46.1-54.1L316 195.5l-11-6.8c-7.3-4.4-11.4-12.6-10.6-21.1l.8-9.8c.6-6.4 3.6-12.3 8.6-16.5l27.3-23c4.7-3.9 10.6-6 16.7-5.7l31.2-1.9c4.7-.3 9.1 2.3 11.1 6.6 12.9 27.3 19.6 57.2 19.5 87.4v4.1c-.4 23.5-4.9 46.7-13.3 68.7-2.1 5.5-4.5 11.2-7.2 16.6 0 0 0 .1-.1.1l-.1.3c-2 4.1-6 6.5-10.3 6.5zm-61.4-131.6l10.9 6.7c5.1 3.1 8.7 8.1 10.1 13.9l12.2 50.7c2.3 9.6 7.1 18.3 13.9 25.4 2.7 2.8 5.6 5.3 8.8 7.4.6-1.4 1.1-2.8 1.6-4.1 7.4-19.5 11.4-40.1 11.8-60.9v-3.8c.1-24.2-4.8-48.2-14.2-70.5l-23.9 1.5h-1.8c-.3 0-.6.1-.8.3l-27.4 23.1c-.3.2-.4.5-.4.8l-.8 9.5z"
      />
      <path
        className="lineal-fill"
        d="M197.9 217.9s-22.1 60.7-32 88a19.44 19.44 0 01-18.3 12.8h-.2c-8.3-.1-15.6-5.4-18.2-13.3l-25.4-75.9c-.5-1.4-1.2-2.7-2.2-3.8-1-1.1-2.2-2-3.5-2.7-7.3-3.7-13.3-19-13.3-19l-2.7-22.2c-1.5-12.6-6.9-24.3-15.4-33.7-8.1-9-18.7-15.3-30.4-18.2l-3.4-.8c-2.5-.6-4.1-3.2-3.5-5.8l.3-.9c27.5-58.3 82.2-99.1 145.9-108.8.4-.1.7.1.9.4L191 42.9c.8 1.6 1.2 3.3 1.2 5v2.3c0 3.9-2 7.5-5.4 9.6l-9.2 5.6c-2.5 1.5-4.2 3.9-5 6.6l-11.8 43.2c-1.7 6.3-8.2 10-14.5 8.3-.4-.1-.8-.2-1.2-.4l-6.9-2.6c-5-1.9-12.3-.9-19.4 1-8.7 2.3-14.8 10.3-14.8 19.3 0 8.9 5.8 16.6 14.3 19.2 7.8 2.3 14.6 20.1 36.2 20.1 0 0 38-4.8 43.2 6.9 4.3 9.8 3.8 20.9.2 30.9z"
      />
      <path
        className="lineal-stroke"
        d="M147.6 330.1h-.2c-13.2-.1-24.9-8.6-29.1-21.1L93 233.2c-10.4-5.3-17-20.5-18.8-25-.4-.9-.6-1.9-.7-2.8l-2.6-22.2c-1.2-10.2-5.6-19.7-12.5-27.3-6.6-7.3-15.2-12.4-24.7-14.8l-3.4-.8c-8.7-2.2-14-11-11.8-19.7.3-1 .6-2 1.1-3 29-61.8 87-105.1 154.6-115.2.6-.1 1.2-.1 1.8-.1 4.7 0 8.9 2.6 11 6.8l14.4 28.8c1.6 3.2 2.4 6.6 2.4 10.2v2.3c0 7.9-4.1 15.3-10.9 19.4l-9.1 5.6-11.8 42.8c-2.8 10.1-12 17.2-22.5 17.2-2.9 0-5.8-.5-8.5-1.6l-6.8-2.6c-.8-.2-1.5-.3-2.3-.2-3.4.1-6.8.7-10 1.6-3.7 1-6.3 4.3-6.3 8.2 0 1.1.2 2.1.6 3.1.9 2.4 2.9 4.3 5.4 5 5.4 1.6 9.3 5.5 13 9.2 5.2 5.2 10.2 10.2 19.3 10.4 5.7-.6 11.5-1 17.3-1 13.1 0 31.2 1.5 37 14.8 5.2 11.8 5.4 25.8.4 39.4 0 0-22.1 60.7-32 88-4.4 12.3-16 20.4-29 20.4zm-44.2-117.4c5.4 2.7 9.4 7.4 11.3 13.1l25.4 75.9c1.1 3.2 4.1 5.4 7.4 5.4h.1c3.3 0 6.3-2.1 7.5-5.2 9.9-27.2 32-88 32-88 2.7-7.5 2.9-15 .6-21.2-1.4-.6-5.7-2.1-16.4-2.1-5.1 0-10.2.3-15.2.9-.5.1-1 .1-1.4.1-19.1 0-29.8-10.8-36.2-17.2-1.2-1.3-2.5-2.5-3.8-3.6-13.1-4.1-22-16.3-22-30 0-14.2 9.6-26.7 23.3-30.4 5.2-1.5 10.6-2.3 16-2.4 3.6-.1 7.1.5 10.4 1.7l7.1 2.7c.1 0 .1 0 .2-.1.1 0 .1-.1.1-.2L161.5 69c1.5-5.6 5.1-10.4 10.1-13.4l9.1-5.5V48l-10.8-21.6c-54.1 10.5-100.4 45-126 93.8 26.9 8.7 46.3 32.3 49.7 60.3l2.4 20.6c2.2 5 5.6 10.4 7.4 11.6z"
      />
    </svg>
  );
};

export default Earth;
