import IconProps from 'types/icon';

const Wallet = ({ className }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 383.36 409.6"
      className={`svg-inject icon-svg ${className || 'icon-svg-sm text-yellow me-5 mt-1'}`}
    >
      <path
        className="lineal-stroke"
        d="M11.5,220.22A11.5,11.5,0,0,1,0,208.72V155.06A77.14,77.14,0,0,1,77.06,78H316.67a11.5,11.5,0,0,1,0,23H77.06A54.12,54.12,0,0,0,23,155.06v53.66a11.5,11.5,0,0,1-11.5,11.5Z"
      />
      <path className="lineal-fill" d="M260.2,138.87a82.37,82.37,0,1,0-138,0Z" />
      <path
        className="lineal-stroke"
        d="M260.2,150.37h-138a11.49,11.49,0,0,1-9.63-5.21A94.23,94.23,0,0,1,124.84,27.49a93.88,93.88,0,0,1,132.75,0,94.23,94.23,0,0,1,12.24,117.67,11.49,11.49,0,0,1-9.63,5.21Zm-131.44-23H253.67a71.18,71.18,0,0,0-12.35-83.61,70.85,70.85,0,0,0-100.21,0,71.18,71.18,0,0,0-12.35,83.61Z"
      />
      <path
        className="lineal-stroke"
        d="M189.6,128.68c-11.73-.3-20.55-6.6-20.55-12,0-2.71,2.43-6.78,5.43-6.78,3.69,0,6.59,6.3,15.12,7.27V97.85c-8.82-3.3-18.8-7.08-18.8-19.49s9.11-17.64,18.8-18.9V56.75c0-1.26,1.46-2.43,3.3-2.43,1.65,0,3.3,1.17,3.3,2.43v2.52c6.3.19,16.57,2,16.57,7.66,0,2.22-1.55,6.68-5.14,6.68-2.71,0-4.94-3.1-11.43-3.58V87.09c8.62,3.2,18.32,7.56,18.32,20.74,0,11.93-7.47,18.81-18.32,20.55v3c0,1.26-1.65,2.42-3.3,2.42-1.84,0-3.3-1.16-3.3-2.42ZM190.38,85V70.32c-4.84.77-7.76,3.1-7.76,6.79,0,4.26,3.3,6.1,7.76,7.85Zm5,15.22V117c4.27-.87,7.27-3.39,7.27-8,0-4.75-3.1-7.07-7.27-8.91Z"
      />
      <path
        className="lineal-stroke"
        d="M319.3,409.6H77.06A77.15,77.15,0,0,1,0,332.54V204.79a77.16,77.16,0,0,1,77.06-77.06H319.31a40.36,40.36,0,0,1,40.31,40.33V234.9a11.5,11.5,0,1,1-23,0V168.06a17.34,17.34,0,0,0-17.31-17.32H77.06a54.12,54.12,0,0,0-54.06,54V332.54A54.12,54.12,0,0,0,77.06,386.6H319.31a17.34,17.34,0,0,0,17.31-17.32V358.36a11.5,11.5,0,0,1,23,0v10.92A40.36,40.36,0,0,1,319.3,409.6Z"
      />
      <path className="lineal-fill" d="M371.86,309.66H280.24a43.82,43.82,0,0,1,0-87.63h91.62Z" />
      <path
        className="lineal-stroke"
        d="M371.86,321.16H280.24a55.32,55.32,0,1,1,0-110.64h91.62A11.52,11.52,0,0,1,383.36,222v87.63a11.51,11.51,0,0,1-11.5,11.5Zm-91.62-87.63a32.32,32.32,0,0,0,0,64.63h80.11V233.53Z"
      />
    </svg>
  );
};

export default Wallet;
