const color = {
  aqua: 'aqua',
  fuchsia: 'fuchsia',
  grape: 'grape',
  green: 'green',
  leaf: 'leaf',
  navy: 'navy',
  orange: 'orange',
  pink: 'pink',
  purple: 'purple',
  red: 'red',
  sky: 'sky',
  blue: 'blue',
  violet: 'violet',
  yellow: 'yellow',
  primary: 'primary'
};

export default color;
