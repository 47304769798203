import IconProps from 'types/icon';

const SmartphoneTwo = ({ className }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 306.7 409.6"
      className={`svg-inject icon-svg ${className || 'icon-svg-sm text-red mb-3'}`}
    >
      <path
        className="lineal-stroke"
        d="M11.1 348.5c-6.1 0-11.1-5-11.1-11.1V72.2C0 66 5 61.1 11.1 61.1h284.5c6.1 0 11.1 5 11.1 11.1V257c0 6.1-5 11.1-11.1 11.1s-11.1-5-11.1-11.1V83.2H22.2v254.2c0 6.2-5 11.2-11.1 11.1 0 .1 0 .1 0 0z"
      />
      <path
        className="lineal-fill"
        d="M48.8 398.5H258c20.8 0 37.7-16.9 37.7-37.7v-23.4H11.1v23.4c0 20.8 16.9 37.7 37.7 37.7z"
      />
      <path
        className="lineal-stroke"
        d="M258 409.6H48.8C21.9 409.6 0 387.7 0 360.8v-23.4c0-6.1 5-11.1 11.1-11.1h284.5c6.1 0 11.1 5 11.1 11.1v23.4c0 26.9-21.8 48.7-48.7 48.8zM22.2 348.5v12.3c0 14.7 11.9 26.6 26.6 26.6H258c14.7 0 26.6-11.9 26.6-26.6v-12.3H22.2zM295.6 83.2H11.1C5 83.2 0 78.3 0 72.2V48.8C0 21.9 21.9 0 48.8 0H258c26.9 0 48.8 21.9 48.8 48.8v23.4c0 6.1-5 11-11.2 11zM22.2 61h262.3V48.8c0-14.7-11.9-26.5-26.5-26.6H48.8c-14.7 0-26.6 11.9-26.6 26.6V61z"
      />
      <path className="st2" d="M65.1 130.8L85 145.3l23.3-28.9" />
      <path
        className="lineal-stroke"
        d="M85 156.4c-2.3 0-4.6-.7-6.5-2.1l-20-14.4c-5-3.6-6.1-10.5-2.5-15.5s10.5-6.1 15.5-2.5l11.5 8.3 16.7-20.7c3.9-4.8 10.9-5.5 15.6-1.6s5.5 10.9 1.6 15.6l-23.3 28.8c-2 2.6-5.2 4.1-8.6 4.1zM241.7 142H159c-6.1-.2-11-5.3-10.8-11.4.2-5.9 4.9-10.6 10.8-10.8h82.7c6.1.2 11 5.3 10.8 11.4-.2 5.9-4.9 10.6-10.8 10.8z"
      />
      <path className="st2" d="M65.1 204.8L85 219.2l23.3-28.8" />
      <path
        className="lineal-stroke"
        d="M85 230.3c-2.3 0-4.6-.7-6.5-2.1l-20-14.4c-5-3.6-6.1-10.5-2.5-15.5s10.5-6.1 15.5-2.5l11.5 8.3 16.7-20.7c3.8-4.8 10.8-5.5 15.6-1.7s5.5 10.8 1.7 15.6l-23.3 28.9c-2.1 2.6-5.3 4.1-8.7 4.1zm156.7-14.4H159c-6.1 0-11.1-5-11.1-11.1s5-11.1 11.1-11.1h82.7c6.1 0 11.1 5 11.1 11.1s-5 11.1-11.1 11.1z"
      />
      <path className="st2" d="M65.1 278.8L85 293.2l23.3-28.9" />
      <path
        className="lineal-stroke"
        d="M85 304.3c-2.3 0-4.6-.7-6.5-2.1l-20-14.4c-5-3.6-6.1-10.5-2.5-15.5s10.5-6.1 15.5-2.5L83 278l16.7-20.7c3.9-4.8 10.9-5.5 15.6-1.7s5.5 10.9 1.7 15.6l-23.3 28.9c-2.1 2.7-5.3 4.2-8.7 4.2zm156.7-14.4H159c-6.1 0-11.1-5-11.1-11.1s5-11.1 11.1-11.1h82.7c6.1 0 11.1 5 11.1 11.1s-5 11.1-11.1 11.1z"
      />
    </svg>
  );
};

export default SmartphoneTwo;
