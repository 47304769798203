import Services1 from './Services1';
import Services2 from './Services2';
import Services3 from './Services3';
import Services4 from './Services4';
import Services5 from './Services5';
import Services6 from './Services6';
import Services7 from './Services7';
import Services8 from './Services8';
import Services9 from './Services9';
import Services10 from './Services10';
import Services11 from './Services11';
import Services12 from './Services12';
import Services13 from './Services13';
import Services14 from './Services14';
import Services15 from './Services15';
import Services16 from './Services16';
import Services17 from './Services17';
import Services18 from './Services18';
import Services19 from './Services19';
import Services20 from './Services20';
import Services21 from './Services21';
import Services22 from './Services22';
import Services23 from './Services23';
import Services24 from './Services24';
import Services25 from './Services25';
import Services26 from './Services26';
import Services27 from './Services27';
import Services28 from './Services28';
import Services29 from './Services29';

export {
  Services1,
  Services2,
  Services3,
  Services4,
  Services5,
  Services6,
  Services7,
  Services8,
  Services9,
  Services10,
  Services11,
  Services12,
  Services13,
  Services14,
  Services15,
  Services16,
  Services17,
  Services18,
  Services19,
  Services20,
  Services21,
  Services22,
  Services23,
  Services24,
  Services25,
  Services26,
  Services27,
  Services28,
  Services29
};
